<template>
  <div>
    <div>
      <van-cell-group>
        <van-field
          v-model="ActLogsFrom.DealTime"
          label="救援时间"
          @click="datafromStartDate = true"
          readonly="readonly"
          required
          placeholder="请选择救援时间"
        />
        <van-popup
          v-model="datafromStartDate"
          position="bottom"
          get-container="body"
        >
          <van-datetime-picker
            show-toolbar
            type="datetime"
            title="请选择救援日期"
            :min-date="minDate"
            :max-date="maxDate"
            @cancel="datafromStartDate = false"
            @confirm="datafromStartDateClick"
          >
          </van-datetime-picker>
        </van-popup>
      </van-cell-group>
      <van-cell-group>
        <van-field
          v-model="ActLogsFrom.Name"
          required
          @click="fuwushow = true"
          readonly="readonly"
          label="救援队伍"
          placeholder="请选择救援队伍"
        />
        <van-popup v-model="fuwushow" position="bottom" get-container="body">
          <van-picker
            show-toolbar
            :columns="fuwuLists"
            value-key="Name"
            @cancel="fuwushow = false"
            @confirm="onfuwushow"
          >
          </van-picker>
        </van-popup>
      </van-cell-group>
      <van-cell-group>
        <van-field
          v-model="ActLogsFrom.DealMan"
          label="救援人"
          placeholder="请输入救援人"
        />
      </van-cell-group>
      <van-cell-group>
        <van-field
          v-model="ActLogsFrom.DealSituation"
          rows="2"
          autosize
          label="情况描述"
          type="textarea"
          placeholder="请输入情况描述"
        />
      </van-cell-group>
      <!-- <div style="margin: 10px">
        <span style="margin-right: 20%; color: #646566; font-size: 14px"
          >图片</span
        >
        <van-uploader
          multiple
          class="imgs"
          :after-read="afterRead"
          :before-delete="jkDelete"
          v-model="ShopImgList"
          accept=""
          :max-count="6"
          :deletable="true"
        >
        </van-uploader>
      </div> -->
      <!-- <div style="margin: 10px">
        <van-uploader
          max-count="1"
          accept="video/*"
          :after-read="handleAvatarSucces"
        >
          <van-button icon="video-o" plain type="info">上传视频</van-button>
        </van-uploader>
        <div
          class="video"
          style="width: 76%; margin: auto; margin-bottom: 75px"
          v-if="ActLogsFrom.Video"
        >
          <video
            style="width: 100%"
            :src="ActLogsFrom.Video"
            controls="controls"
          >
            您的浏览器不支持 video 标签。
          </video>
        </div>
      </div> -->
      <!-- <div style="margin: 10px">
        <span style="margin-right: 20%; color: #646566; font-size: 14px"
          >视频</span
        >
        <van-uploader
          :after-read="upload"
          :before-delete="fjDelete"
          v-model="fileList"
          accept=""
          :max-count="1"
        >
        </van-uploader>
        <van-button icon="plus" plain style="border-radius: 7px" type="info"
          >上传附件</van-button
        >
      </div> -->
    </div>
    <div>
      <div style="width: 95%; bottom: 0; position: fixed; padding: 10px">
        <van-button
          type="info"
          size="normal"
          style="
            text-center: right;
            font-size: 16px;
            width: 100%;
            border-radius: 7px;
          "
          @click="saveRecActLogs()"
          color="#617bfa"
          >提交</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Compressor from "compressorjs";
import { DealResort, UploadQiniuUpToken, WxGetTeamPage } from "@/api/RealInfo";
import { setOpenId, getOpenId, getwgToken } from "@/utils/auth";
import config from "@/config";
import Vue from "vue";
import { Toast } from "vant";
import { Dialog } from "vant";
import axios from "axios";
Vue.use(Toast);
export default {
  data() {
    return {
      fuwushow: false,
      ImgsLists: [],
      ShopImgList: [],
      datafromStartDate: false,
      datafromEndDate: false,
      qiniutokent: {}, //上传视频传参
      ImageList: [],
      minDate: new Date(2023, 0),
      maxDate: new Date(2033, 0),
      list: {}, //活动详情
      ActLogsFrom: {
        accToken: getwgToken(),
        ResortId: "",
        DealTeamId: "",
        Name: "",
        DealMan: "",
        DealSituation: "",
        DealTime: this.dateformat(new Date()),
      }, //活动申请提交参数
      fileList: [],
      fuwuLists: [],
    };
  },
  created() {
    this.qiniutoken();
    this.getStaServiceList();
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
  },
  methods: {
    // 获取救援队伍
    getStaServiceList() {
      WxGetTeamPage({ page: 1, limit: 999 }).then((res) => {
        console.log(res);
        this.fuwuLists = res.data.data;
      });
    },
    // 救援队伍选择器
    onfuwushow(val) {
      console.log(val);
      this.ActLogsFrom.DealTeamId = val.TeamId;
      this.ActLogsFrom.Name = val.Name;
      this.fuwushow = false;
    },
    // 开始日期
    datafromStartDateClick(val) {
      this.ActLogsFrom.DealTime = this.dateformat(val);
      this.datafromStartDate = false;
    },
    dateformat(val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      // 获取小时
      var hour = val.getHours();
      // 获取分钟
      var minute = val.getMinutes();
      // 获取秒钟
      var second = val.getSeconds();
      if (month >= 0 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 0 && day <= 9) {
        day = `0${day}`;
      }
      if (hour >= 0 && hour <= 9) {
        hour = `0${hour}`;
      }
      if (minute >= 0 && minute <= 9) {
        minute = `0${minute}`;
      }
      if (second >= 0 && second <= 9) {
        second = `0${second}`;
      }
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    },
    // 获取七牛云token
    qiniutoken() {
      UploadQiniuUpToken().then((res) => {
        this.qiniutokent.token = res.data.token;
      });
    },
    // 上传 相关
    handleAvatarSucces(file) {
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
        loadingType: "spinner",
      });
      let url = "http://upload-z2.qiniup.com";
      let formData = new FormData();
      formData.append("token", this.qiniutokent.token);
      formData.append("file", file.file); //必须是file.file，不然会报错
      //添加请求头
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      axios.post(url, formData, config).then((res) => {
        this.ActLogsFrom.Video = "http://videolib.dexian.ren/" + res.data.key;
      });
    },
    // 上传附件
    upload: function (file) {
      var that = this;
      console.log(file.file);
      file.status = "uploading";
      file.message = "上传中...";
      var formData = new FormData();
      formData.append("file", file.file); // 文件对象
      let c = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      axios.post(config.apiUploadFiles, formData, c).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data.data);
          that.ActLogsFrom.Annex = res.data.data;
          file.status = "";
          file.message = "";
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    //上传照片
    afterRead(file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success(result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios.post(config.apiUploadUrl, formData, c).then((res) => {
            if (res.data.code === 0) {
              console.log(res.data.picurl[0]);
              // that.ActLogsFrom.Imgs.push(res.data.picurl[0]);
              that.ImgsLists.push(res.data.picurl[0]);
              file.status = "";
              file.message = "";
            } else {
              Toast.fail(res.data.msg);
            }
          });
        },
      });
    },
    //删除照片
    jkDelete(file, detail) {
      console.log(file, detail);
      this.ShopImgList.splice(detail.index, 1);
      this.ImgsLists.splice(detail.index, 1);
    },
    // 删除附件
    fjDelete: function () {
      this.fileList = [];
      this.ActLogsFrom.Annex = "";
    },
    saveRecActLogs: function () {
      if (this.ActLogsFrom.DealTeamId == "") {
        Toast.fail("请选择救援队伍");
        return false;
      }
      // if (this.ActLogsFrom.Imgs == "") {
      //   Toast.fail("请上传图片");
      //   return false;
      // }
      this.ActLogsFrom.ResortId = this.$route.params.id;
      console.log(this.ActLogsFrom, "this.ActLogsFrom");
      DealResort(this.ActLogsFrom).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            message: "处理成功!",
          }).then(() => {
            // this.$router.go(-2);
            window.wx.miniProgram.navigateTo({
              url: "../index/member/myFangKong/index?path=/shop/help",
            });
            // window.wx.miniProgram.navigateBack({
            //   delta: 1,
            // });
          });
        } else {
          Dialog.alert({
            message: "处理失败," + res.data.msg,
          }).then(() => {});
        }
      });
    },
  },
};
</script>

<style scoped>
.buttons {
  text-align: center;
}
.buttons button {
  margin: 25px 10px;
  border-radius: 10px;
}
/deep/ .van-uploader {
  vertical-align: middle;
}
</style>