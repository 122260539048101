import { render, staticRenderFns } from "./chuli.vue?vue&type=template&id=ebb2434e&scoped=true&"
import script from "./chuli.vue?vue&type=script&lang=js&"
export * from "./chuli.vue?vue&type=script&lang=js&"
import style0 from "./chuli.vue?vue&type=style&index=0&id=ebb2434e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebb2434e",
  null
  
)

export default component.exports